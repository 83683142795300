/** @format */

import React from "react";
import { BsArrowRight } from "react-icons/bs";
function Hero() {
  return (
    <div className='container mt-3 mb-5'>
      <div className='row'>
        <h1 className='heading' data-aos='fade-down' data-aos-duration='1500'>
          Growtogether with Relymer and learn <br /> all you need to know about
          web 3.0 and more!
        </h1>
        <div className='col-md-4'>
          <div className='card hero-cards'  data-aos='fade-right'
          data-aos-duration='1500' >
            <div className='card-body'>
              <img src='assets/Processor.svg' alt='' />
              <h2>Front End Development</h2>
              <p>
                Market compatible package and great working environment for the
                right incumbent. During the internship you will learn how to be
                a professional software engineer and build world class
                international projects. Internship duration is 3 months. For
                more details feel free to fill the form.
              </p>
              <a
                target='_blank'
                rel='noreferrer'
                href='https://docs.google.com/forms/d/e/1FAIpQLSdRsrHpVjYuRGd-S66CtXA2GkbAPS--UnKS2ayffl-MRWvSgA/viewform'>
                <button className='form-btn'>
                  Apply <BsArrowRight />
                </button>
              </a>
            </div>
          </div>
        </div>
        <div className='col-md-4'>
          <div className='card hero-cards'  data-aos='fade-up'
          data-aos-duration='1500'>
            <div className='card-body'>
              <img src='assets/Processor.svg' alt='' />
              <h2>Backend Development</h2>
              <p>
                We are looking for a potential candidates who are ready to work
                with the team settings with an ability to take full technical
                ownership about node.js as an internee. The internship duration
                is 3 months. For more details feel free to fill the form.
              </p>
              <a
                target='_blank'
                rel='noreferrer'
                href='https://docs.google.com/forms/d/e/1FAIpQLSdRsrHpVjYuRGd-S66CtXA2GkbAPS--UnKS2ayffl-MRWvSgA/viewform'>
                <button className='form-btn'>
                  Apply <BsArrowRight />
                </button>
              </a>
            </div>
          </div>
        </div>
        <div className='col-md-4'>
          <div className='card hero-cards'   data-aos='fade-right'
          data-aos-duration='1500'>
            <div className='card-body'>
              <img src='assets/Processor.svg' alt='' />
              <h2>Blockchain Technology</h2>
              <p>
                We are looking for individuals who thrive in a culture of
                builders and overachievers and embrace high performance,
                transparent feedback, and a mission-first approach. Our culture
                shapes our way of working and we will make you blockchain
                experts. Internship duration is 3 months. For more details feel
                free to fill the form.
              </p>
              <a
                target='_blank'
                rel='noreferrer'
                href='https://docs.google.com/forms/d/e/1FAIpQLSdRsrHpVjYuRGd-S66CtXA2GkbAPS--UnKS2ayffl-MRWvSgA/viewform'>
                <button className='form-btn'>
                  Apply <BsArrowRight />
                </button>
              </a>
            </div>
          </div>
        </div>
        <div className='col-md-4 mt-4'>
          <div className='card hero-cards' data-aos='fade-right'
              data-aos-duration='1500'>
            <div className='card-body'>
            <img src='assets/Processor.svg' alt='' />

              <h2>Graphic Designing</h2>
              <p>
                Looking for an energetic & quicker Learning Candidates for
                internship program who want to learn the new things in the field
                of Art and Design. Internship duration is 3 months. For more
                details feel free to fill the form.
              </p>
              <a
                target='_blank'
                rel='noreferrer'
                href='https://docs.google.com/forms/d/e/1FAIpQLSdEpcbiIfrXbSJWHLDpR_g50WXsdc1tdjMj8x5jsT12XkJsZw/viewform'>
                <button className='form-btn'>
                  Apply <BsArrowRight />
                </button>
              </a>
            </div>
          </div>
        </div>
        <div className='col-md-4 mt-4'>
          <div className='card hero-cards'  data-aos='fade-up'
          data-aos-duration='1500'>
            <div className='card-body'>
            <img src='assets/Processor.svg' alt='' />

              <h2>Game Development</h2>
              <p>
                Do you love games and want to develop one on your own? Gain
                hands-on experience by making use of our excellent internship
                opportunity. As a Unity intern you will get exposure to a lot of
                techniques needed to develop real time 3D games online. Our
                super fun and creative game development experts are the best
                part of this internship. Internship duration is 3 months. For
                more details feel free to fill the form.
              </p>
              <a
                target='_blank'
                rel='noreferrer'
                href='https://docs.google.com/forms/d/e/1FAIpQLSdRsrHpVjYuRGd-S66CtXA2GkbAPS--UnKS2ayffl-MRWvSgA/viewform'>
                <button className='form-btn'>
                  Apply <BsArrowRight />
                </button>
              </a>
            </div>
          </div>
        </div>
        <div className='col-md-4 mt-4'>
          <div className='card hero-cards'   data-aos='fade-right'
          data-aos-duration='1500'>
            <div className='card-body'>
            <img src='assets/Processor.svg' alt='' />

              <h2>Content Writer</h2>
              <p>
                We’re looking for talented writers (ENGLISH) that can help us
                put together mostly long-form content for blogs, guides, ebooks,
                guest posts, and more! If you’re eager to learn and have
                attention to detail – we want you! Internship duration is 3
                months. For more details feel free to fill the form.
              </p>
              <a
                target='_blank'
                rel='noreferrer'
                href='https://docs.google.com/forms/d/e/1FAIpQLSdRsrHpVjYuRGd-S66CtXA2GkbAPS--UnKS2ayffl-MRWvSgA/viewform'>
                <button className='form-btn'>
                  Apply <BsArrowRight />
                </button>
              </a>
            </div>
          </div>
        </div>
      </div>
     
    </div>
  );
}

export default Hero;
