/** @format */

// import React, { useState } from "react";
// import axios from "axios";
import { GoLocation } from "react-icons/go";
import { FiPhoneCall } from "react-icons/fi";
import { BsArrowRight } from "react-icons/bs";
import {
  FaTwitter,
  FaFacebook,
  FaLinkedin,
  FaInstagram,
  FaEnvelope,
} from "react-icons/fa";

export default function Footer() {
  // const [msg, setMsg] = useState("");

  // const [to, setTo] = useState("");

  // const [subject, setSubject] = useState("");

  // const [description, setDescription] = useState("");

  // const onInputChange = e => {
  //   setTo(e.target.value);
  // };

  // const onInputChange1 = e => {
  //   setSubject(e.target.value);
  // };

  // const onInputChange2 = e => {
  //   setDescription(e.target.value);
  // };
  // const user = {
  //   to: to,
  //   subject: subject,
  //   description: description,
  // };
  // const onSubmit = async e => {
  //   e.preventDefault();
  // await  fetch("http://localhost:5000/user/", user)
  // .then(response => response.json())
  // .then(json => console.log(json))
  //   // await axios
  //   //   .post("http://localhost:5000/home/", user)
  //   //   .then(response => setMsg(response.data.respMesg));
  //   // console.log(user);
  // };

  return (
    <div className='container footer-padding' id='Contact'>
      <div className='row'>
        <div className='col-md-6 footer'  data-aos='fade-right'
          data-aos-duration='1500'>
          <div>
            <span>Contact Us</span>
            <h2>Kickstart Your Digital Journey Today....</h2>
            <p>
              Share your proposals, ideas and comments with us. We are looking
              forward for them.
            </p>
            <h1>Pakistan(HQ)</h1>
          </div>
          <div className='icon-text'>
            <GoLocation className='icon' />
            <p className='mt-3 mx-3'>
            First Floor, Ace Uptown, Plot # 22-A, North Road, E 11/2 Islamabad            </p>
          </div>
          <div className=' icon-text'>
            <FiPhoneCall className='icon' />
            <a className='phone mx-3 text-dark' href='tel:+923337359637'>
              +92 333 7359637
            </a>
          </div>
        </div>
        <div className='col-md-6'  data-aos='fade-up'
          data-aos-duration='1500'>
      
            <div className='input-group mb-3'>
              <input
                type='text'
                className='form-control'
                placeholder='Username'
                aria-label='Username'
                aria-describedby='basic-addon1'
              />
            </div>
            <div className='input-group mb-3'>
              <input
                type='text'
                className='form-control'
                placeholder='Email'
                aria-label='Email'
                name="email"
                aria-describedby='basic-addon1'
                // onChange={onInputChange}
                // value={to}
              />
            </div>
            <div class='input-group mb-3'>
              <input
                type='text'
                className='form-control'
                placeholder='Subject (Optional)'
                aria-label='Email'
                name="subject"
                aria-describedby='basic-addon1'
                // onChange={onInputChange1}
                // value={subject}
              />
            </div>
            <div class='input-group'>
              <textarea
                className='form-control'
                aria-label='With textarea'
                placeholder='Write your message..'
                // onChange={onInputChange2}
                // value={description}
                rows={10}></textarea>
            </div>
            <p class='mb-3 mt-2' style={{ color: "green", marginLeft: "57px" }}>
              {/* <b>{msg}</b> */}
            </p>
            <button className='footer-btn' >
              Submit <BsArrowRight className='mx-2' />
            </button>
        </div>
      </div>
      <div className='row'>
        <div className='col-md-12'>
          <div className='text-center footer-bottom'>
            <p>© Relymer Pvt Ltd. 2022. All Rights Reserved</p>
            <a href='https://twitter.com/relymer'  target="_blank" rel="noreferrer">
              <FaTwitter size={25} />
            </a>
            <a href='https://www.facebook.com/relymer' target="_blank"  rel="noreferrer">
              <FaFacebook size={25} />
            </a>
            <a href='https://www.instagram.com/relymercom' target="_blank" rel="noreferrer">
              <FaInstagram size={25} />
            </a>
            <a href='https://www.linkedin.com/company/relymer-group/mycompany/' target="_blank"  rel="noreferrer">
              <FaLinkedin size={25} />
            </a>
            <a href='mailto:hr@relymer.com' >
              <FaEnvelope size={25} />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
