/** @format */

import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.scss";
import AOS from "aos";
import { useEffect } from "react";

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle";
import "aos/dist/aos.css";
import Base from "./components/layouts/Base";
import Home from "./pages/Home";

function App() {
  useEffect(() => {
    AOS.init({});
  });
  return (
    <>
      <BrowserRouter>
        <Base>
          <Routes>
            <Route index path='/' element={<Home />} />
            <Route  path='/*' element={<Home />} />
          </Routes>
        </Base>
      </BrowserRouter>
    </>
  );
}

export default App;
